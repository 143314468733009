import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
    return (
        <Svg viewBox="0 0 40 40" {...props}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.6216 0.85942C18.7283 -0.286473 21.2721 -0.286474 23.3787 0.85942L35.5915 7.50256C37.8671 8.74033 39.2836 11.1233 39.2836 13.7137V26.2863C39.2836 28.8767 37.8671 31.2597 35.5915 32.4974L23.3787 39.1406C21.2721 40.2865 18.7283 40.2865 16.6216 39.1406L4.40883 32.4974C2.1333 31.2597 0.716797 28.8767 0.716797 26.2863L0.716797 13.7137C0.716797 11.1233 2.1333 8.74033 4.40882 7.50256L16.6216 0.85942Z" fill="#00ACED"/>
            <g clip-path="url(#clip0_141_39506)">
            <path d="M21.2393 16.3654L26.6196 10L32 16.3654H21.2393Z" fill="white"/>
            <path d="M29.4088 15.4273H23.9141V26.6374H29.4088V15.4273Z" fill="white"/>
            <path d="M21.9527 18.1331H16.458V26.6374H21.9527V18.1331Z" fill="white"/>
            <path d="M14.4947 22.3849H9V26.6366H14.4947V22.3849Z" fill="white"/>
            <path d="M29.4092 27.8741H9V30H29.4092V27.8741Z" fill="white"/>
            </g>
            <defs>
            <clipPath id="clip0_141_39506">
            <rect width="23" height="20" fill="white" transform="translate(9 10)"/>
            </clipPath>
            </defs>
        </Svg>
    );
};

export default Icon;
