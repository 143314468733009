import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
    return (
        <Svg viewBox="0 0 20 20" {...props}>
            <path d="M10.8347 4.16633C10.8347 3.70609 10.4616 3.33299 10.0014 3.33299C9.54114 3.33299 9.16805 3.70609 9.16805 4.16633V10.9049L6.84056 8.57741C6.51512 8.25198 5.98748 8.25198 5.66205 8.57741C5.33661 8.90285 5.33661 9.43049 5.66205 9.75592L8.82283 12.9167C9.4737 13.5676 10.529 13.5676 11.1798 12.9167L14.339 9.75755C14.6644 9.43212 14.6644 8.90448 14.339 8.57904C14.0136 8.2536 13.4859 8.2536 13.1605 8.57904L10.8347 10.9048V4.16633Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.33333 11.6667C3.79357 11.6667 4.16667 12.0398 4.16667 12.5V14.1667C4.16667 14.6269 4.53976 15 5 15H15C15.4602 15 15.8333 14.6269 15.8333 14.1667V12.5C15.8333 12.0398 16.2064 11.6667 16.6667 11.6667C17.1269 11.6667 17.5 12.0398 17.5 12.5V14.1667C17.5 15.5474 16.3807 16.6667 15 16.6667H5C3.61929 16.6667 2.5 15.5474 2.5 14.1667V12.5C2.5 12.0398 2.8731 11.6667 3.33333 11.6667Z" fill="white"/>
        </Svg>
    );
};

export default Icon;
