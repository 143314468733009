import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
    return (
        <Svg viewBox="0 0 40 40" {...props}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.6216 0.85942C18.7283 -0.286473 21.2721 -0.286474 23.3787 0.85942L35.5915 7.50256C37.8671 8.74033 39.2836 11.1233 39.2836 13.7137V26.2863C39.2836 28.8767 37.8671 31.2597 35.5915 32.4974L23.3787 39.1406C21.2721 40.2865 18.7283 40.2865 16.6216 39.1406L4.40883 32.4974C2.1333 31.2597 0.716797 28.8767 0.716797 26.2863L0.716797 13.7137C0.716797 11.1233 2.1333 8.74033 4.40882 7.50256L16.6216 0.85942Z" fill="#355DFF"/>
            <path d="M22.9097 10.26C21.6797 10.26 20.6797 11.26 20.6797 12.49V17.13C20.6797 18.36 21.6797 19.36 22.9097 19.36C24.1397 19.36 25.1397 18.36 25.1397 17.13V12.49C25.1397 11.27 24.1397 10.26 22.9097 10.26Z" fill="white"/>
            <path opacity="0.4" d="M28.5494 15.49C27.4794 15.49 26.6094 16.36 26.6094 17.43V19C26.6094 19.21 26.7794 19.38 26.9894 19.38H28.5594C29.6294 19.38 30.4994 18.51 30.4994 17.44C30.4994 16.37 29.6194 15.49 28.5494 15.49Z" fill="white"/>
            <path d="M16.87 15.37H12.23C11 15.37 10 16.37 10 17.6C10 18.83 11 19.83 12.23 19.83H16.87C18.1 19.83 19.1 18.83 19.1 17.6C19.1 16.37 18.1 15.37 16.87 15.37Z" fill="white"/>
            <path opacity="0.4" d="M17.1597 10C16.0897 10 15.2197 10.87 15.2197 11.94C15.2197 13.01 16.0897 13.88 17.1597 13.88H18.7297C18.9397 13.88 19.1097 13.71 19.1097 13.5V11.93C19.0997 10.88 18.2297 10 17.1597 10Z" fill="white"/>
            <path d="M17.8003 21.41C16.5703 21.41 15.5703 22.41 15.5703 23.64V28.28C15.5703 29.51 16.5703 30.51 17.8003 30.51C19.0303 30.51 20.0303 29.51 20.0303 28.28V23.64C20.0303 22.41 19.0303 21.41 17.8003 21.41Z" fill="white"/>
            <path opacity="0.4" d="M13.7297 21.41H12.1597C11.0897 21.41 10.2197 22.28 10.2197 23.35C10.2197 24.42 11.0897 25.29 12.1597 25.29C13.2297 25.29 14.0997 24.42 14.0997 23.35V21.78C14.0997 21.58 13.9297 21.41 13.7297 21.41Z" fill="white"/>
            <path d="M28.4794 20.95H23.8394C22.6094 20.95 21.6094 21.95 21.6094 23.18C21.6094 24.41 22.6094 25.41 23.8394 25.41H28.4794C29.7094 25.41 30.7094 24.41 30.7094 23.18C30.7094 21.95 29.7094 20.95 28.4794 20.95Z" fill="white"/>
            <path opacity="0.4" d="M23.5496 26.85H21.9796C21.7696 26.85 21.5996 27.02 21.5996 27.23V28.8C21.5996 29.87 22.4696 30.74 23.5396 30.74C24.6096 30.74 25.4796 29.87 25.4796 28.8C25.4896 27.72 24.6196 26.85 23.5496 26.85Z" fill="white"/>
        </Svg>
    );
};

export default Icon;
