import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
    return (
        <Svg viewBox="0 0 40 40" {...props}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.6216 0.85942C18.7283 -0.286473 21.2721 -0.286474 23.3787 0.85942L35.5915 7.50256C37.8671 8.74033 39.2836 11.1233 39.2836 13.7137V26.2863C39.2836 28.8767 37.8671 31.2597 35.5915 32.4974L23.3787 39.1406C21.2721 40.2865 18.7283 40.2865 16.6216 39.1406L4.40883 32.4974C2.1333 31.2597 0.716797 28.8767 0.716797 26.2863L0.716797 13.7137C0.716797 11.1233 2.1333 8.74033 4.40882 7.50256L16.6216 0.85942Z" fill="#F2B10A"/>
            <path d="M20.75 22.52H20.98C21.31 22.52 21.59 22.22 21.59 21.85C21.59 21.38 21.48 21.32 21.16 21.21L20.75 21.07V22.52Z" fill="white"/>
            <path d="M20 10C14.48 10 10 14.48 10 20C10 25.52 14.48 30 20 30C25.52 30 30 25.52 30 20C30 14.48 25.52 10 20 10ZM15.58 24.42C15.87 24.71 15.87 25.19 15.58 25.48C15.43 25.63 15.24 25.7 15.05 25.7C14.86 25.7 14.67 25.63 14.52 25.48C13.06 24.02 12.25 22.07 12.25 20C12.25 17.93 13.06 15.98 14.52 14.52C14.81 14.23 15.29 14.23 15.58 14.52C15.87 14.81 15.87 15.29 15.58 15.58C14.4 16.76 13.75 18.33 13.75 20C13.75 21.67 14.4 23.24 15.58 24.42ZM21.65 19.78C22.61 20.12 23.08 20.79 23.08 21.84C23.08 23.04 22.14 24.01 20.97 24.01H20.74V24.19C20.74 24.6 20.4 24.94 19.99 24.94C19.58 24.94 19.24 24.6 19.24 24.19V24.01H19.21C17.94 24.01 16.9 22.94 16.9 21.63C16.9 21.22 17.24 20.88 17.65 20.88C18.06 20.88 18.4 21.22 18.4 21.63C18.4 22.12 18.76 22.51 19.21 22.51H19.24V20.53L18.34 20.21C17.38 19.87 16.91 19.2 16.91 18.15C16.91 16.95 17.85 15.98 19.02 15.98H19.25V15.8C19.25 15.39 19.59 15.05 20 15.05C20.41 15.05 20.75 15.39 20.75 15.8V15.98H20.78C22.05 15.98 23.09 17.05 23.09 18.36C23.09 18.77 22.75 19.11 22.34 19.11C21.93 19.11 21.59 18.77 21.59 18.36C21.59 17.87 21.23 17.48 20.78 17.48H20.75V19.46L21.65 19.78ZM25.48 25.48C25.33 25.63 25.14 25.7 24.95 25.7C24.76 25.7 24.57 25.63 24.42 25.48C24.13 25.19 24.13 24.71 24.42 24.42C25.6 23.24 26.25 21.67 26.25 20C26.25 18.33 25.6 16.76 24.42 15.58C24.13 15.29 24.13 14.81 24.42 14.52C24.71 14.23 25.19 14.23 25.48 14.52C26.94 15.98 27.75 17.93 27.75 20C27.75 22.07 26.94 24.02 25.48 25.48Z" fill="white"/>
            <path d="M18.4199 18.16C18.4199 18.63 18.5299 18.69 18.8499 18.8L19.2599 18.94V17.48H19.0299C18.6899 17.48 18.4199 17.79 18.4199 18.16Z" fill="white"/>
        </Svg>
    );
};

export default Icon;
