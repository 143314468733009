import { Flex } from "@pancakeswap/uikit"
import styled from "styled-components"

export const ContainerCard = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 2rem;
    @media screen and (max-width:1024px) and (min-width:768px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem;
    }
    @media screen and (max-width:600px) {
        grid-template-columns: 1fr;
        grid-row-gap: 1rem;
    }
`

export const ContainerRow = styled.div<{isHeader?:boolean}>`
    width: 100%;
    display: grid;
    height: ${({ isHeader }) => isHeader ? `58px` : "100%"};
    align-items: center;
    padding: 0rem 0rem 0rem 1rem;
    border-radius: ${({ isHeader }) => isHeader ? `12px` : "0px"};
    grid-template-columns: 12% 19% 9% 16% 12% 16% 16%;
    background: ${({ isHeader, theme }) => isHeader ? theme.colors.backgroundAlt2 : "transparent"};
    border-bottom: ${({ isHeader, theme }) => !isHeader ? `1px solid ${theme.colors.cardBorder}` : "none"};
    @media screen and (max-width:768px) {
        display: ${({ isHeader }) => isHeader ? "none" :"grid"};
    }
    @media screen and (max-width:768px) {
        grid-template-columns: 1fr;
        grid-row-gap: 8px;
        padding:1rem;
        width: 100%;
        max-width: 350px;
        border: 1px solid ${({ theme }) => theme.colors.cardBorder};
        border-radius: 12px;
        
    }
    @media screen and (max-width:600px) {
        max-width: 100%;
    }
`

export const Tooltip = styled.div<{ isTooltipDisplayed: boolean }>`
    display: ${({ isTooltipDisplayed }) => (isTooltipDisplayed ? 'inline-block' : 'none')};
    position: absolute;
    padding: 8px;
    top: -35px;
    right: -15px;
    text-align: center;
    background-color: #fff;
    color: #000;
    border-radius: 16px;
    width: 100px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
`
export const Mask = styled.div`
    width: 100%;
    height: 100%;
    z-index: 10;
    cursor:pointer;
`
export const ContainerLink = styled(Flex)`
    height: 68px;
    width: 100%;
    position: relative;
    @media screen and (max-width:768px) {
        height: 300px;
        max-width: 350px;
    }
    @media screen and (max-width:600px) {
        height: 400px;
        max-width: 100%;
    }
`
export const StyledButtonDownloadExcel = styled.div`
    position: relative;
    background: ${({ theme }) => theme.colors.primary};
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 130px;
    height: 48px;
    @media screen and (max-width: 768px) {
        min-width: 40px;
    }
    div{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        height: 36px;
            p{
                font-size: 14px;
                padding: 0px;
                color: ${({ theme }) => theme.colors.white};
                font-weight: bold;
                @media screen and (max-width: 768px) {
                    display: none;
                }
            }
    }
    .download-table-xls-button {
        cursor: pointer;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border: none;
        background: none;
    }
`
