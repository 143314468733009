import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
    return (
        <Svg {...props} width="20" height="20" viewBox="0 0 20 20" fill={props.fill}>
            <path
                d="M9.58317 18.125C4.87484 18.125 1.0415 14.2917 1.0415 9.58332C1.0415 4.87499 4.87484 1.04166 9.58317 1.04166C14.2915 1.04166 18.1248 4.87499 18.1248 9.58332C18.1248 14.2917 14.2915 18.125 9.58317 18.125ZM9.58317 2.29166C5.55817 2.29166 2.2915 5.56666 2.2915 9.58332C2.2915 13.6 5.55817 16.875 9.58317 16.875C13.6082 16.875 16.8748 13.6 16.8748 9.58332C16.8748 5.56666 13.6082 2.29166 9.58317 2.29166Z"
                fill={props.fill}
            />
            <path
                d="M18.3335 18.9583C18.1752 18.9583 18.0169 18.9 17.8919 18.775L16.2252 17.1083C15.9835 16.8667 15.9835 16.4667 16.2252 16.225C16.4669 15.9833 16.8669 15.9833 17.1085 16.225L18.7752 17.8917C19.0169 18.1333 19.0169 18.5333 18.7752 18.775C18.6502 18.9 18.4919 18.9583 18.3335 18.9583Z"
                fill={props.fill}
            />
        </Svg>
    );
};

export default Icon;
