import { Flex, Heading, Skeleton, Text } from "@pancakeswap/uikit";
import useTheme from "hooks/useTheme";
import React, { ReactNode, cloneElement, isValidElement } from "react";
import { formatNumber } from "utils/formatNumber";

interface Props {
    icon: ReactNode;
    title: string
    value: string|number,
    unit?:string,
    isLoading?:boolean
}
const Card:React.FC<Props> = ({
    icon, title, value, unit, isLoading
}) => {
    const { theme } = useTheme()
    return (
        <Flex 
            width="100%" 
            flexDirection="column" 
            style={{gap:"1rem"}} 
            border={`1px solid ${theme.colors.cardBorder}`}
            borderRadius="12px"
            padding="1rem 2rem"
        >
            <Flex width="100%">
                {isValidElement(icon) &&
                    cloneElement(icon)}
            </Flex>
            <Flex width="100%">
                <Text fontSize="18px" color="textB9">{title}</Text>
            </Flex>
            <Flex width="100%" style={{gap:"1rem"}} alignItems="center">
                { isLoading ?
                    <Skeleton width="52px" />
                :
                    <Heading scale="lg">
                        {formatNumber(value)}
                    </Heading>
                }
                
                { unit &&
                    <Flex padding="8px 8px 6px 8px" background={theme.colors.green} borderRadius="8px">
                        <Text color="white">{unit}</Text>
                    </Flex>
                }
            </Flex>
        </Flex>
    )
}
export default Card